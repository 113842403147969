import React from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Layout from "../components/layout";

export default () => {

  return (
    <Layout>

    <div style={{ 'position': 'relative' }}>
      <Link to='/oer-filmmusik' className="closeX"><span>X</span></Link>
      <h2>Projekte zur OER Filmmusik</h2>
    </div>
    <div style={{ 'marginTop': '20px', 'display': 'flex' }}>
      <Link to="/oer-filmmusik"><img style={{ 'height': '200px', 'marginRight': '20px', 'marginTop': '20px' }} src="/Images/kaiser_openbookFilmmusik.jpg" alt="" /></Link>
      <p>
         In dem OpenBook <Link to="/oer-filmmusik">Filmmusik</Link> wird die Eröffnungssequenz des Films <i>Sintel</i> besprochen und zum Komponieren einer eigenen Filmmusik zu dieser Szene angeregt. Auf dieser Seite finden Sie die in dem OpenBook gegebenen Beispiele als Film sowie als Projekte (Cakewalk- und Shotcut-Projekt). Darüber hinaus werden hier auch einige Arbeiten meiner Studierenden zur Verfügung gestellt, die in meinem Online-Proseminar <i>Filmmusik</i> im Sommersemester 2020 an der <a href="https://www.hmtm.de/de/" target="_blank">Hochschule für Musik und Theater München</a> entstanden sind. Die Sounddesigns bzw. Filmmusiken aller Nachvertonungen stehen wie der Originalfilm der Blender Foundation unter der freien Lizenz <a href="https://creativecommons.org/licenses/by/3.0/">Creative Commons Attribution 3.0</a>. Der Film wurde in allen Fällen unverändert übernommen, die originale Filmmusik von <Link to="/additionals-sintel-morgenstern">Jan Morgenstern</Link> durch eine eigene Bearbeitung ersetzt.
      </p>
    </div>
    <hr style={{ "margin": "40px 0" }} />
    <div className="gridBox">
      <div className="gridFraction">
        <video style={{ 'maxWidth': '100%' }} poster="/images/poster-sintel.png" controls>
          <source src="/publicfiles/sintel/sintel-kaiser.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="gridFraction">
        Sounddesign: Ulrich Kaiser<br />
        Verwendete Musikbeispiele:
        <ul style={{ 'listStylePosition': 'inside' }}>
          <li>L. v. Beethoven, 7. Sinfonie, 2. Satz</li>
          <li>G. Verdi, Requiem, Dies Irae</li>
          <li>G. Mahler, 5. Sinfonie, 2. Satz</li>
          <li>P. I. Tschaikowski, 6. Sinfonie, 1. Satz</li>
          <li>R. Leoncavallo, Il Pagliacci (Schluss)</li>
          <li>35mm-Sample</li>
        </ul>
      </div>    
      <div className="gridFraction" style={{ 'flexDirection': 'row' }}>
        <a className="downloadLink" href="/publicfiles/sintel/sintel-cakewalk-projekt.zip">Download als Cakewalk-Projekt</a>
        <a className="downloadLink" href="/publicfiles/sintel/sintel-shotcut-projekt.zip">Download als Shotcut-Projekt</a>
      </div>
      <div className="gridFraction">
        
      </div>   
    </div>
    <div style={{ 'marginTop': '40px' }}>
      Hier werden demnächst noch weitere Vertonungen der Eröffnungssequenz des Sintel-Films zur Verfügung gestellt. Bitte haben Sie noch ein wenig Geduld...
    </div>
    <Helmet>
      <title>OER | Ulrich Kaiser - Sintel Projekt</title>
      <meta name="description" content="Hier finden Sie Vertonungen der Eröffnungssequenz des Films Sintel von Blender zum einem OpenBook (OER) Filmmusik von Ulrich Kaiser." />
      <meta name="keywords" content="Filmmusik, Sintel, OER, Open Educational Resources, OpenBooks" />
      <meta name="copyright" content="Ulrich Kaiser, 2011" />
    </Helmet>
  </Layout>
  )
}

